import {type ClassValue, clsx} from 'clsx';
import {twMerge} from 'tailwind-merge';
import {NB_ANNEES_SIMU} from '~/legacy/utils/constantes';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const completeArray = (tab: number[]) => {
  const ret = [...tab];
  let length = ret.length;
  if (length < NB_ANNEES_SIMU) {
    const lastValue = ret[length - 1];
    for (let i = length; i < NB_ANNEES_SIMU; i += 1) {
      ret.push(lastValue);
    }
  }
  return tab;
};
